<template>
  <section id="filters-body">
    <div class="wrapper" :style="{ 'flex-direction': `${direction}` }">
      <div
        v-for="filter in filterModel"
        :key="filter.name"
        :class="filter.name"
      >
        <!-- :style="`flex-basis: ${(filter.width / 12) * 100}%`" -->
        <component
          :ref="`filter-${filter.name}`"
          :is="filter.component"
          v-bind="filter.props"
          v-on="$listeners"
        />
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'FiltersBody',
  props: {
    filterModel: {
      type: Array,
      required: true
    },
    direction: {
      type: String,
      default: 'row'
    }
  },
  components: {
    BotFilter: () => import('./components/BotFilter.vue'),
    BotNoVersionFilter: () => import('./components/BotNoVersionFilter.vue'),
    DateRangeFilter: () => import('./components/DateRangeFilter.vue'),
    ReportFilter: () => import('./components/ReportFilter.vue'),
    IntentFilter: () => import('./components/IntentFilter.vue'),
    SurveyFilter: () => import('./components/SurveyFilter.vue'),
    PlatformFilter: () => import('./components/PlatformFilter.vue'),
    StatusFilter: () => import('./components/StatusFilter.vue'),
    TextFilter: () => import('./components/TextFilter.vue'),
    TrainerStatusFilter: () => import('./components/TrainerStatusFilter.vue'),
    CompanyStatusFilter: () => import('./components/CompanyStatusFilter.vue'),
    StagingFilter: () => import('./components/StagingFilter.vue'),
    QueueFilter: () => import('./components/QueueFilter.vue')
  }
}
</script>

<style lang="scss">
#filters-body {
  .wrapper {
    display: flex !important;
    flex-wrap: wrap !important;
    justify-content: space-around;
    width: 100% !important;
  }
}
</style>
